import { m } from "framer-motion";
import styled from "styled-components";

export const Background = styled(m.div)`
  position: absolute;
  left: 50%;
  top: 50vh;
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 200vh;
  height: 200vh;
  overflow-x: hidden;
  overflow-y: hidden;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  justify-content: center;

  @media screen and (orientation: landscape) {
    width: 200vw;
    height: 200vw;
  }
`;

export const Inner = styled(m.div)`
  background-color: rgba(118, 183, 42, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150vh;
  height: 150vh;
  div {
    opacity: 0;
  }

  @media screen and (orientation: landscape) {
    width: 150vw;
    height: 150vw;
  }
`;
