import { Background, Inner } from "./style";
import React from "react";
import { m } from "framer-motion";
import IneronLogo2 from "../../images/Ineron.svg";

const list = {
  hidden: {
    opacity: 0,
    display: "none",
    transition: { when: "afterChildren" },
  },
};

const item = {
  hidden: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    transition: { when: "afterChildren", duration: 0.75 },
  },
};

const logo = {
  hidden: {
    opacity: 1,
    transition: { repeat: 1, repeatType: "reverse", duration: 1 },
  },
};

const Loading = () => {
  return (
    <>
      <Background key='background' variants={list} animate='hidden'>
        <Inner key='inner' variants={item}>
          <m.div key='logo-cont' variants={logo}>
            <IneronLogo2 key='logo' />
          </m.div>
        </Inner>
      </Background>
    </>
  );
};

export default Loading;
