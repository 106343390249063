import styled from "styled-components";

export const Main = styled.main`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const Section = styled.section`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1336px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Ring = styled.section`
  position: absolute;
  max-width: 1440px;
  max-height: 1440px;
  min-width: 500px;
  min-height: 500px;
  width: 110vh;
  height: 110vh;
  left: 50%;
  top: 50vh;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  border: 2px solid rgba(118, 183, 42, 1);
  pointer-events: none;
  @media screen and (orientation: landscape) {
    width: 150vh;
    height: 150vh;
  }

  @media screen and (orientation: portrait) and (max-height: 1200px) {
    width: 110vh;
    height: 110vh;
    filter: blur(0.7px);
  }
`;
