import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { Main, Section, Ring } from "./style";

const BackgroundSection = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90, breakpoints: [1080, 1366, 1440, 1920], placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  );

  // Set ImageData.
  const image = getImage(desktop);

  return (
    <Wrapper>
      <Background image={image} loading='eager' alt='background' />
      <Ring />
      <Main>
        <Section>{children}</Section>
      </Main>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;
const Background = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    filter: blur(0.7px);
  }
`;

export default BackgroundSection;
