import React from "react";
import { AnimatePresence, LazyMotion, domAnimation } from "framer-motion";
import BackgroundWrapper from "./src/components/pageBackground";
import Loading from "./src/components/loadingWrapper/";
import { GlobalStyle } from "./src/styles/globalStyle";

export function wrapPageElement({ element }) {
  return (
    <>
      <GlobalStyle />
      <LazyMotion features={domAnimation}>
        <AnimatePresence exitBeforeEnter>
          <BackgroundWrapper>
            <Loading key='loading' />
            {element}
          </BackgroundWrapper>
        </AnimatePresence>
      </LazyMotion>
    </>
  );
}
